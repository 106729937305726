import * as BABYLON from "babylonjs";
import classNames from "classnames";
import DraggableModal from "components/DraggableModal";
import { MaterialSelectOptions } from "components/project-components/MaterialSelectOptions";
import * as React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { setPopupOpen } from "state/reducers/generalSlice";
import { addHistory } from "state/reducers/historySlice";
import { selectFirstSelected, selectModels } from "state/reducers/modelSlice";
import { selectParameters } from "state/reducers/parametersSlice";
import { selectRefresh } from "state/reducers/refreshSlice";
import { selectMaterials } from "state/reducers/userSlice";
import {
  calculate,
  centralPos,
  isParameter,
  replaceParametersToIds,
} from "utilities";
import { v4 as uuid } from "uuid";

export interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  mainScene: BABYLON.Scene | any;
  isEditing?: boolean;
  editingExtrusion?: any;
}

let gizmo: BABYLON.AxisDragGizmo | null = null;

function ExtrudeMenu({
  visible,
  setVisible,
  mainScene,
  isEditing,
  editingExtrusion,
}: Props) {
  const [extrusionLength, setExtrusionLength] = useState("0");
  const [factor, setFactor] = useState("1");
  const [isCanCopy, setIsCanCopy] = useState(false);

  const [isPositionChanged, setIsPositionChanged] = useState(false);

  const [name, setName] = useState("Extrusion");

  const materials = useAppSelector(selectMaterials);
  const models = useAppSelector(selectModels);
  const selectedModels = models.filter((model) => model.selected);
  const refresh = useAppSelector(selectRefresh);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat();
  const dispatch = useAppDispatch();
  const firstSelected: any = useAppSelector(selectFirstSelected);
  const parameters = useAppSelector(selectParameters);
  const username = useAppSelector(selectUsername);
  const { projectId } = useParams<{ projectId: string }>();

  const [isValid, setIsValid] = useState(true);
  const saving = useAppSelector((state) => state.histories.saving);
  const [previewModels, setPreviewModels] = useState<any[]>([]);
  const [allowPickFace, setAllowPickFace] = useState(false);
  const [originalPosition, setOriginalPosition] =
    useState<BABYLON.Vector3 | null>(null);
  const [observer, setObserver] = useState<any>();
  const [selected, setSelected] = useState<BABYLON.PickingInfo>();
  const [material, setMaterial] = useState("PEC");
  const [originalHoverMaterial, setOriginalHoverMaterial] =
    React.useState<BABYLON.Material | null>(null);
  const [gizmoNormal, setGizmoNormal] = React.useState<BABYLON.Vector3 | null>(
    null
  );

  React.useEffect(() => {
    setName(editingExtrusion?.name || "Extrusion");
    setExtrusionLength(editingExtrusion?.object?.length || "0");
    setMaterial(editingExtrusion?.material || "PEC");
  }, [editingExtrusion, visible]);

  React.useEffect(() => {
    if (visible) {
      dispatch(setPopupOpen(true));
    } else if (!visible) {
      dispatch(setPopupOpen(false));
    }
  }, [visible, dispatch]);

  // React.useEffect(() => {
  //   // Initialize GizmoManager here
  //   if (mainScene && !gizmoManager && visible) {
  //     gizmoManager = new GizmoManager(mainScene);
  //   }
  // }, [mainScene, visible]);

  const getWorldCenter = () => {
    if (!selected?.pickedMesh) return;
    var vertexData = BABYLON.VertexData.ExtractFromMesh(
      selected?.pickedMesh as BABYLON.Mesh
    );

    // Get the indices of the picked face
    var indices = vertexData.indices;
    var positions = vertexData.positions;
    var faceId = selected.faceId;

    if (indices) {
      if (positions) {
        let faceSet = new Set();
        indices.forEach((e, index) => {
          if (
            (indices && e === indices[faceId * 3]) ||
            (indices && e === indices[faceId * 3 + 1]) ||
            (indices && e === indices[faceId * 3 + 2])
          ) {
            faceSet.add(Math.floor(index / 3));
          }
        });

        // Collect all unique vertices of the face(s)
        let vertices: BABYLON.Vector3[] = [];
        faceSet.forEach((faceIndex: any) => {
          for (let i = 0; i < 3; i++) {
            if (!indices || !positions) return;

            let index = indices[faceIndex * 3 + i];
            vertices.push(
              new BABYLON.Vector3(
                positions[index * 3],
                positions[index * 3 + 1],
                positions[index * 3 + 2]
              )
            );
          }
        });

        // Remove duplicate vertices
        vertices = Array.from(
          new Set(vertices.map((v) => `${v.x},${v.y},${v.z}`))
        ).map((v) => {
          let [x, y, z] = v.split(",").map(Number);
          return new BABYLON.Vector3(x, y, z);
        });

        // Calculate the center of the face
        let center = vertices
          .reduce((acc, v) => acc.add(v), new BABYLON.Vector3(0, 0, 0))
          .scale(1 / vertices.length);

        // Get the world matrix of the mesh
        var worldMatrix = selected.pickedMesh.getWorldMatrix();

        // Transform the local center to world space
        var worldCenter = BABYLON.Vector3.TransformCoordinates(
          center,
          worldMatrix
        );

        return worldCenter;
      }
    }
  };

  const getFaceRotation = (
    p0: BABYLON.Vector3,
    p1: BABYLON.Vector3,
    p2: BABYLON.Vector3
  ) => {
    // Calculate face normal
    var edge1 = p1.subtract(p0);
    var edge2 = p2.subtract(p0);
    var normal = BABYLON.Vector3.Cross(edge2, edge1).normalize();

    // Choose an arbitrary tangent direction (we'll use edge1)
    var tangent = edge1.normalize();

    // Ensure tangent is perpendicular to normal
    tangent = BABYLON.Vector3.Cross(
      normal,
      BABYLON.Vector3.Cross(tangent, normal)
    ).normalize();

    // Calculate bitangent
    var bitangent = BABYLON.Vector3.Cross(normal, tangent);

    // Create rotation matrix from these orthonormal vectors
    var rotationMatrix = BABYLON.Matrix.Identity();
    BABYLON.Matrix.FromXYZAxesToRef(tangent, normal, bitangent, rotationMatrix);

    // Convert to quaternion
    var quaternion = BABYLON.Quaternion.FromRotationMatrix(rotationMatrix);

    return quaternion;
  };

  React.useEffect(() => {
    removePreviews();
    if (!visible) {
      // Clear the gizmos when the menu is hidden
      if (gizmo) {
        gizmo.attachedMesh?.dispose();
        gizmo.dispose();
      }

      if (observer) {
        mainScene.onPointerObservable.remove(observer);
        mainScene.meshes.map((m: any) => m.id[0] === "." && m?.dispose());
      }

      return;
    }

    const keyDownFunc = (event: any) => {
      if (event.key === "Escape") {
        document.getElementById("translate-cancel-btn")?.click();
        document.removeEventListener("keydown", keyDownFunc);
      } else if (event.key === "Enter") {
        document.getElementById("translate-ok-btn")?.click();
        document.removeEventListener("keydown", keyDownFunc);
      }
    };
    document.addEventListener("keydown", keyDownFunc);

    if (mainScene) {
      if (gizmo) {
        gizmo.dispose();
        gizmo.attachedMesh?.dispose();
      }
    }

    if (selected && selectedModels.length >= 1) {
      // place a position gizmo at 0,0,0
      const parent = new BABYLON.Mesh("gizmoParent", mainScene);
      parent.setBoundingInfo(
        new BABYLON.BoundingInfo(
          new BABYLON.Vector3(0, 0, 0),
          new BABYLON.Vector3(0, 0, 0)
        )
      );
      const model = selectedModels[0];
      const mesh = mainScene.getMeshById(model.id);

      const sizes = mesh.getHierarchyBoundingVectors();
      const size = {
        x: sizes.max.x - sizes.min.x,
        y: sizes.max.y - sizes.min.y,
        z: sizes.max.z - sizes.min.z,
      };

      parent.position = new BABYLON.Vector3(
        centralPos(selectedModels, mainScene).x,
        centralPos(selectedModels, mainScene).y,
        centralPos(selectedModels, mainScene).z
      );

      if (selected?.pickedMesh) {
        parent.rotation.set(
          selected.pickedMesh.rotation.x,
          selected.pickedMesh.rotation.y,
          selected.pickedMesh.rotation.z
        );
        var vertexData = BABYLON.VertexData.ExtractFromMesh(
          selected?.pickedMesh as BABYLON.Mesh
        );

        // Get the indices of the picked face
        var indices = vertexData.indices;
        var positions = vertexData.positions;
        var faceId = selected.faceId;

        if (indices) {
          // Indices for the picked face (each face is a triangle)
          var index0 = indices[faceId * 3];
          var index1 = indices[faceId * 3 + 1];
          var index2 = indices[faceId * 3 + 2];

          if (positions) {
            let faceSet: any = new Set();
            indices.forEach((e, index) => {
              if (
                (indices && e === indices[faceId * 3]) ||
                (indices && e === indices[faceId * 3 + 1]) ||
                (indices && e === indices[faceId * 3 + 2])
              ) {
                faceSet.add(Math.floor(index / 3));
              }
            });

            // Collect all unique vertices of the face(s)
            let vertices: BABYLON.Vector3[] = [];
            faceSet.forEach((faceIndex: any) => {
              for (let i = 0; i < 3; i++) {
                if (!indices || !positions) return;

                let index = indices[faceIndex * 3 + i];
                vertices.push(
                  new BABYLON.Vector3(
                    positions[index * 3],
                    positions[index * 3 + 1],
                    positions[index * 3 + 2]
                  )
                );
              }
            });

            const facets = [...faceSet.values()];

            // Remove duplicate vertices
            vertices = Array.from(
              new Set(vertices.map((v) => `${v.x},${v.y},${v.z}`))
            ).map((v) => {
              let [x, y, z] = v.split(",").map(Number);
              return new BABYLON.Vector3(x, y, z);
            });

            // Calculate the center of the face
            let center = vertices
              .reduce((acc, v) => acc.add(v), new BABYLON.Vector3(0, 0, 0))
              .scale(1 / vertices.length);

            // Vertices of the picked face
            var p0 = BABYLON.Vector3.FromArray(positions, index0 * 3);
            var p1 = BABYLON.Vector3.FromArray(positions, index1 * 3);
            var p2 = BABYLON.Vector3.FromArray(positions, index2 * 3);

            // Calculate the normal of the picked face
            var edge1 = p1.subtract(p0);
            var edge2 = p2.subtract(p0);
            var crossNormal = BABYLON.Vector3.Cross(edge2, edge1).normalize();
            let normal = BABYLON.Vector3.Zero();

            selected.pickedMesh.getFacetNormalToRef(facets[0], normal);

            var utilLayer = new BABYLON.UtilityLayerRenderer(mainScene);
            gizmo = new BABYLON.AxisDragGizmo(
              crossNormal,
              BABYLON.Color3.FromHexString("#00b894"),
              utilLayer
            );
            setGizmoNormal(normal);
            console.log("setting gizmo normal to", normal);

            // Get the world matrix of the mesh
            var worldMatrix = selected.pickedMesh.getWorldMatrix();

            // Transform the local center to world space
            var worldCenter = BABYLON.Vector3.TransformCoordinates(
              center,
              worldMatrix
            );

            gizmo.attachedMesh = parent;
            parent.position.set(worldCenter.x, worldCenter.y, worldCenter.z);

            console.log(
              "Picked Mesh:",
              selected.pickedMesh.rotation.x,
              selected.pickedMesh.rotation.y,
              selected.pickedMesh.rotation.z
            );

            let initialPosition = parent.position.clone();

            gizmo.dragBehavior.onDragObservable.add((e) => {
              let m: any = gizmo?.attachedMesh;
              let distance = BABYLON.Vector3.Distance(m.position, worldCenter);

              let dragDirection = m.position
                .subtract(initialPosition)
                .normalize();
              let dotProduct = BABYLON.Vector3.Dot(dragDirection, normal);

              let roundedDistance = Math.round(distance * 100) / 100;

              if (dotProduct < 0) {
                roundedDistance = -roundedDistance;
              }

              setExtrusionLength(roundedDistance.toString());
            });
          }
        }
      }
      setOriginalPosition(parent.position.clone());

      // parent.rotation.set(
      //   selected?.pickedMesh?.rotation.x || 0,
      //   selected?.pickedMesh?.rotation.y || 0,
      //   selected?.pickedMesh?.rotation.z || 0
      // );

      console.log("set rotation to", parent.rotation);

      var coloryellow = new BABYLON.StandardMaterial("Jaune", mainScene);
      coloryellow.emissiveColor = new BABYLON.Color3(1, 1, 0);

      if (gizmo) {
        // set colors
        // gizmo.coloredMaterial.diffuseColor = new BABYLON.Color3(1, 1, 0);
        // gizmo.hoverMaterial.diffuseColor = new BABYLON.Color3(0, 1, 1);
        // gizmo.yGizmo.coloredMaterial.diffuseColor = new BABYLON.Color3(1, 1, 0);
        // gizmo.yGizmo.hoverMaterial.diffuseColor = new BABYLON.Color3(0, 1, 1);
        // gizmo.zGizmo.coloredMaterial.diffuseColor = new BABYLON.Color3(1, 1, 0);
        // gizmo.zGizmo.hoverMaterial.diffuseColor = new BABYLON.Color3(0, 1, 1);
      }
    }
  }, [visible, selected]);

  React.useEffect(() => {
    if (observer) {
      mainScene.onPointerObservable.remove(observer);
    }
    if (!visible || !allowPickFace) return;

    let lastClickTime = 0;
    const doubleClickDelay = 300; // milliseconds

    setObserver(
      mainScene.onPointerObservable.add((e: any) => {
        if (e.type === BABYLON.PointerEventTypes.POINTERMOVE) {
          const pickResult = mainScene.pick(
            mainScene.pointerX,
            mainScene.pointerY
          );
          if (pickResult.hit && pickResult.pickedMesh) {
            pickQuads(pickResult, mainScene);
          }
        } else if (
          e.type === BABYLON.PointerEventTypes.POINTERDOWN &&
          e.pickInfo.hit
        ) {
          if (
            selectedModels.length >= 1 &&
            e.pickInfo.pickedMesh.id !== selectedModels[0].id
          )
            return;

          const currentTime = new Date().getTime();
          if (currentTime - lastClickTime < doubleClickDelay) {
            // Double click detected
            setSelected(e.pickInfo);
            pickQuads(e.pickInfo, mainScene);
            setAllowPickFace(false);
            // Log camera position after selecting a face
            // console.log("Camera Position:", mainScene.activeCamera.position);
          }
          lastClickTime = currentTime;
        }
      })
    );
  }, [selected, visible, allowPickFace, originalHoverMaterial]);

  function pickQuads(pickInfo: BABYLON.PickingInfo, scene: BABYLON.Scene) {
    let { pickedMesh, faceId } = pickInfo;
    let indices = pickedMesh?.getIndices();
    if (pickedMesh && indices) {
      let faceSet = new Set();
      indices.forEach((e, index) => {
        if (
          (indices && e === indices[faceId * 3]) ||
          (indices && e === indices[faceId * 3 + 1]) ||
          (indices && e === indices[faceId * 3 + 2])
        ) {
          faceSet.add(Math.floor(index / 3));
        }
      });

      return createPickFaceMesh(
        Array.from(faceSet.values()),
        pickedMesh,
        scene
      );
    }
  }

  const moveArrow = React.useCallback(
    (distance: number) => {
      if (gizmo && gizmoNormal && gizmo.attachedMesh && selected?.pickedMesh) {
        if (originalPosition) {
          gizmo.attachedMesh.position = originalPosition.clone();
        }
        const direction = gizmoNormal
          .clone()
          .multiplyByFloats(
            distance / selected.pickedMesh.scaling.x,
            distance / selected.pickedMesh.scaling.y,
            distance / selected.pickedMesh.scaling.z
          );

        // Add the scaled direction to the mesh's position
        gizmo.attachedMesh.position.addInPlace(direction);
      }
    },
    [gizmoNormal, gizmo, gizmo?.attachedMesh, originalPosition]
  );

  function createPickFaceMesh(
    faces: any[],
    pickedMesh: BABYLON.AbstractMesh,
    scene: BABYLON.Scene
  ) {
    let newIndices: any = [];
    let indices = pickedMesh.getIndices();
    let vertices = pickedMesh.getVerticesData(
      BABYLON.VertexBuffer.PositionKind
    );
    let uniqueVertices = new Set(); // Use a Set to store unique vertex strings

    if (!indices || !pickedMesh || !scene) return;

    faces.forEach((e) => {
      if (indices) {
        newIndices.push(indices[e * 3], indices[e * 3 + 1], indices[e * 3 + 2]);
      }
    });

    if (pickedMesh instanceof BABYLON.Mesh) {
      try {
        let clone = pickedMesh.clone();
        let geo = pickedMesh.geometry?.copy(scene.getUniqueId().toString());
        geo?.setIndices(newIndices);
        geo?.applyToMesh(clone);
        clone.renderOverlay = true;
        clone.isPickable = false;

        if (!vertices) return;

        // Check and add only unique vertices to the Set
        newIndices.forEach((index: any) => {
          if (vertices) {
            let vertexStr = `${vertices[index * 3]},${
              vertices[index * 3 + 1]
            },${vertices[index * 3 + 2]}`;
            uniqueVertices.add(vertexStr);
          }
        });

        const coordinatesArray = Array.from(uniqueVertices);
        const verticesArr = coordinatesArray.map((coords) => {
          const [x, y, z] = (coords as string).split(",").map(Number);
          return new BABYLON.Vector3(x, y, z);
        });
        // uniqueVertices.forEach((vertexStr) => {
        //   console.log(`Unique Vertex Position: (${vertexStr})`);
        // });
        // Convert the shape array to Vector3[]
        const shapeArray: BABYLON.Vector3[] = verticesArr.map((coords) => {
          return new BABYLON.Vector3(coords.x, coords.y, coords.z);
        });

        if (mainScene.meshes.filter((m: any) => m.id[0] === ".").length >= 2)
          mainScene.meshes.find((m: any) => m.id[0] === ".")?.dispose();

        // Create a polygon mesh from the vertices
        var polygonMesh = BABYLON.MeshBuilder.CreatePolygon(
          "polygon-mesh-face",
          { shape: shapeArray, depth: 10 },
          scene
        );

        // Move the polygon mesh upward
        polygonMesh.position.y = 10;
        handlePreview();
        return clone;
      } catch (e) {
        console.log("err", e);
      }
    }
  }

  const removePreviews = () => {
    if (!mainScene) return;

    mainScene.meshes.forEach((mesh: BABYLON.Mesh) => {
      if (mesh?.name && mesh.name.startsWith("extrude_shape")) {
        mesh.dispose();
      }
    });
  };

  const getMaterial = (color: any) => {
    if (!materials) {
      return new BABYLON.StandardMaterial("PEC", mainScene);
    }
    const material = Object.keys(materials).find(
      (material) => material === color
    );
    if (material) {
      const meshMaterial = new BABYLON.StandardMaterial(material, mainScene);
      meshMaterial.diffuseColor = BABYLON.Color3.FromHexString(
        materials[material]?.color
      );
      meshMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
      return meshMaterial;
    }
    const meshMaterial = new BABYLON.StandardMaterial("PEC", mainScene);
    meshMaterial.diffuseColor = BABYLON.Color3.FromHexString(
      materials["PEC"]?.color
    );
    meshMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
    return meshMaterial;
  };

  const handlePreview = () => {
    if (!selected) return;

    const xValue = calculate(
      replaceParametersToIds(extrusionLength, parameters),
      parameters
    );

    const mesh: BABYLON.AbstractMesh | null = selected.pickedMesh;

    if (!mesh) return;
    removePreviews();

    const faceId = selected.faceId;
    const extrurde_L = xValue;
    const rotations = mesh.rotation.clone();

    mesh.rotation.setAll(0);
    mesh.computeWorldMatrix(true);

    //const myBox = BABYLON.MeshBuilder.CreateBox("box", {height: 5, width: 7, depth: 3, faceColors: greenColorArray})

    const indices = mesh.getIndices();

    if (!indices) return;

    // Determine all facets linked to the picked facet
    let facetSet: any = new Set();
    indices.forEach((e, index) => {
      if (
        e === indices[faceId * 3] ||
        e === indices[faceId * 3 + 1] ||
        e === indices[faceId * 3 + 2]
      ) {
        facetSet.add(Math.floor(index / 3));
      }
    });

    // Facet array
    const facets = [...facetSet.values()];
    //console.log(facets)

    const vertices = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind);

    if (!vertices) return;

    // Only take the first normal, to avoid numerical errors
    let normalDir = BABYLON.Vector3.Zero();

    mesh.getFacetNormalToRef(facets[0], normalDir);
    normalDir = new BABYLON.Vector3(
      normalDir.x / Math.pow(mesh.scaling.x, 2),
      normalDir.y / Math.pow(mesh.scaling.y, 2),
      normalDir.z / Math.pow(mesh.scaling.z, 2)
    );

    // Create facet polyhedrons one by one
    var polyList = [];
    for (let polyIdx = 0; polyIdx < facets.length; polyIdx++) {
      // Extract facet vertices
      let v1 = [
        vertices[indices[facets[polyIdx] * 3 + 0] * 3 + 0],
        vertices[indices[facets[polyIdx] * 3 + 0] * 3 + 1],
        vertices[indices[facets[polyIdx] * 3 + 0] * 3 + 2],
      ];

      let v2 = [
        vertices[indices[facets[polyIdx] * 3 + 1] * 3 + 0],
        vertices[indices[facets[polyIdx] * 3 + 1] * 3 + 1],
        vertices[indices[facets[polyIdx] * 3 + 1] * 3 + 2],
      ];

      let v3 = [
        vertices[indices[facets[polyIdx] * 3 + 2] * 3 + 0],
        vertices[indices[facets[polyIdx] * 3 + 2] * 3 + 1],
        vertices[indices[facets[polyIdx] * 3 + 2] * 3 + 2],
      ];

      // Create vertices for far end of extruded facet
      // const normalizedNormalDir = new BABYLON.Vector3(
      //   normalDir.x / Math.pow(mesh.scaling.x, 2),
      //   normalDir.y / Math.pow(mesh.scaling.y, 2),
      //   normalDir.z / Math.pow(mesh.scaling.z, 2)
      // );

      let v1n = [
        v1[0] + normalDir.x * extrurde_L,
        v1[1] + normalDir.y * extrurde_L,
        v1[2] + normalDir.z * extrurde_L,
      ];

      let v2n = [
        v2[0] + normalDir.x * extrurde_L,
        v2[1] + normalDir.y * extrurde_L,
        v2[2] + normalDir.z * extrurde_L,
      ];

      let v3n = [
        v3[0] + normalDir.x * extrurde_L,
        v3[1] + normalDir.y * extrurde_L,
        v3[2] + normalDir.z * extrurde_L,
      ];

      let vList = [v1, v2, v3, v1n, v2n, v3n];

      let faceList = [
        [0, 1, 2],
        [1, 0, 3, 4],
        [2, 1, 4, 5],
        [0, 2, 5, 3],
        [5, 4, 3],
      ];

      if (extrurde_L < 0) {
        faceList = [
          [2, 1, 0],
          [4, 3, 0, 1],
          [5, 4, 1, 2],
          [3, 5, 2, 0],
          [3, 4, 5],
        ];
      }

      let cPolyhedron = {
        name: "facet_extrude_" + polyIdx,
        category: ["extrusion"],
        vertex: vList,
        face: faceList,
      };

      let cPoly = BABYLON.MeshBuilder.CreatePolyhedron(
        "ext_" + polyIdx,
        { custom: cPolyhedron },
        mainScene
      );
      polyList.push(cPoly);

      cPoly.material = getMaterial(material);
      cPoly.material.zOffset = 0.5;
    }

    var addCSG = BABYLON.CSG.FromMesh(polyList[0]);
    for (let polyIdx = 1; polyIdx < facets.length; polyIdx++) {
      let bCSG = BABYLON.CSG.FromMesh(polyList[polyIdx]);
      addCSG = addCSG.union(bCSG);
    }

    const addMesh = addCSG.toMesh("extrude_shape", null, mainScene);

    addMesh.position = mesh.position;
    addMesh.scaling = mesh.scaling;
    addMesh.rotation = mesh.rotation;

    for (let polyIdx = 0; polyIdx < facets.length; polyIdx++) {
      polyList[polyIdx].dispose();
      mainScene.removeMesh(polyList[polyIdx]);
    }

    addMesh.material = getMaterial(material);

    mesh.rotation.copyFrom(rotations);
    mesh.rotationQuaternion = null;
  };

  const handleCancel = () => {
    removePreviews();
    setVisible(false);
  };

  const handleOk = async () => {
    const mesh = mainScene.meshes.find((m: BABYLON.Mesh) =>
      m?.name?.startsWith("extrude_shape")
    );

    if (!isEditing && (!mesh || selectedModels.length === 0 || !selected))
      return;

    if (isEditing) {
      await dispatch(
        addHistory({
          payloadData: {
            edit_extrude: {
              parentId: editingExtrusion?.object?.originalId,
              id: editingExtrusion?.id,
              name: name,
              faceId: editingExtrusion?.object?.faceId,
              length: extrusionLength,
              material: material,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    } else if (selected) {
      await dispatch(
        addHistory({
          payloadData: {
            extrude: {
              parentId: selectedModels[0].id,
              id: uuid(),
              name: name,
              faceId: selected.faceId,
              length: extrusionLength,
              material: material,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    }
    // dispatch(setRefresh({ refresh: refresh + 1 }));

    setVisible(false);
  };

  const [updateGizmoPosition, setUpdateGizmoPosition] = React.useState(0);

  const handleAxisChange = (value: string, setAxis: (val: string) => void) => {
    if (isParameter(value, parameters)) {
      setIsPositionChanged(true);
    }
    setAxis(value);
    setUpdateGizmoPosition(updateGizmoPosition + 1);
  };

  React.useEffect(() => {
    if (!visible) return;

    const parsedLength =
      calculate(
        replaceParametersToIds(extrusionLength, parameters),
        parameters
      ) || 0;

    moveArrow(parsedLength);
  }, [visible, extrusionLength, selected, gizmo?.attachedMesh]);

  React.useEffect(() => {
    if (!extrusionLength) return;

    const parsedLength = calculate(
      replaceParametersToIds(extrusionLength, parameters),
      parameters
    );

    console.log(getWorldCenter());
  }, [updateGizmoPosition]);

  const handleFactorChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFactor(e.target.value);
  };

  const handleIsCanCopyChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCanCopy(e.target.checked);
  };

  React.useEffect(() => {
    if (visible) handlePreview();

    if (!visible) {
      setExtrusionLength("0");
      setFactor("1");
      setIsCanCopy(false);
      setAllowPickFace(false);
      setMaterial("PEC");
      setSelected(undefined);
    }
  }, [
    visible,
    extrusionLength,
    factor,
    isCanCopy,
    previewModels,
    material,
    selected,
  ]);

  return (
    <DraggableModal
      title={
        <div className="pointer-events-auto cursor-pointer bg-red-300 w-full text-xl font-semibold rounded-t-md py-2 text-center border-b-2 border-gray-800">
          Extrude
        </div>
      }
      visible={visible}
      buttons={
        <div className="flex flex-row gap-1 w-full justify-between">
          <div />
          <div className="flex gap-2">
            <button
              onClick={handleCancel}
              id="translate-cancel-btn"
              disabled={saving}
              className="bg-red-300 enabled:hover:bg-red-400 enabled:active:bg-red-500 rounded text-center px-4 py-1 disable-drag disabled:opacity-50"
            >
              Cancel
            </button>
            <button
              onClick={handleOk}
              id="translate-ok-btn"
              disabled={!isValid || saving}
              className={`rounded text-center px-4 py-1 disable-drag bg-green-300 enabled:hover:bg-green-400 enabled:active:bg-green-500 disabled:opacity-50`}
            >
              {saving ? (
                <svg
                  aria-hidden="true"
                  className="w-6 h-6 text-gray-900 animate-spin dark:text-gray-600 fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "OK"
              )}
            </button>
          </div>
        </div>
      }
    >
      <form>
        <div className="flex w-full flex-col">
          <label
            htmlFor="ExtrusionName"
            className="flex text-sm font-large leading-6 text-gray-900 mr-2"
          >
            Name
          </label>
          <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
            <input
              type="text"
              name="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              id="ExtrusionName"
              autoComplete="off"
              className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
            />
          </div>
        </div>

        <div className="flex-grow border-t border-gray-400 my-2"></div>

        {!isEditing && (
          <button
            className={classNames(
              `block flex-1 py-2 px-4 w-full items-center justify-center text-gray-900 placeholder:text-gray-400 cursor-pointer sm:text-sm sm:leading-6 rounded-md sm:max-w-lg ring-1 ring-inset ring-primary-600`,
              {
                "bg-teal-400 hover:bg-teal-500 active:bg-teal-600 hover:transition duration-150":
                  allowPickFace,
              }
            )}
            onClick={() => {
              setAllowPickFace(true);
            }}
            disabled={allowPickFace}
            type="button"
          >
            Pick Face
          </button>
        )}

        <div className="mt-4 grid grid-cols-1 gap-x-3 gap-y-3">
          <div className="col-span-full flex items-center">
            <label
              htmlFor="Extrusion Length"
              className="flex text-sm font-large leading-6 text-gray-900 mr-2"
            >
              Length
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <input
                type="text"
                name="Extrusion Length"
                value={extrusionLength}
                onChange={(e) => {
                  handleAxisChange(e.target.value, setExtrusionLength);
                }}
                id="ExtrusionLength"
                autoComplete="off"
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm rounded-md shadow-sm ring-1 ring-inset ring-primary-600"
              />
            </div>
          </div>

          <div className="col-span-full ">
            <label
              htmlFor="material"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Material
            </label>
            <div className="flex rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600 disable-drag">
              <select
                name="material"
                id="material"
                value={material}
                onChange={(e) => {
                  setMaterial(e.target.value);
                }}
                className="flex flex-1 border-0 bg-transparent py-1.5 px-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 rounded-md shadow-sm sm:max-w-lg ring-1 ring-inset ring-primary-600"
              >
                <MaterialSelectOptions options={materials ?? {}} />
              </select>
            </div>
          </div>
        </div>
      </form>
    </DraggableModal>
  );
}

export default ExtrudeMenu;
