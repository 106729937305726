import { scene } from "../MainScene";

import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import React, { useEffect, useState } from "react";

import MyIcon from "assets/MyIcons";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { selectUsername } from "state/reducers/authSlice";
import { v4 as uuid } from "uuid";
import AnalyticMenu from "../babylonjs/ActionsBar/Create/AnalyticMenu";
import CubeMenu from "../babylonjs/ActionsBar/Create/CubeMenu";
import CylinderMenu from "../babylonjs/ActionsBar/Create/CylinderMenu";
import SphereMenu from "../babylonjs/ActionsBar/Create/SphereMenu";
import RotateMenu from "../babylonjs/ActionsBar/Transform/RotateMenu";
import TranslateMenu from "../babylonjs/ActionsBar/Transform/TranslateMenu";

import { STLFileLoader } from "babylonjs-loaders";
import { STLExport } from "babylonjs-serializers";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import booleanOperations from "services/booleanOperations.service";
import step2stl from "services/step2stl.service";
import { addHistory } from "state/reducers/historySlice";
import {
  modelAdded,
  modelAltered,
  modelRemoved,
  selectFirstSelected,
  selectModels,
  setFirstSelected,
} from "state/reducers/modelSlice";
import { selectMaterials } from "state/reducers/userSlice";
import ExtrudeMenu from "../babylonjs/ActionsBar/Transform/ExtrudeMenu";
import ScaleMenu from "../babylonjs/ActionsBar/Transform/ScaleMenu";
import { hideMeshes, showMeshes } from "./TabUtils";

interface ModelingTabProps {
  projectId: string;
  objects: any[];
  selectedObjects: any[];
  setObjects: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedObjects: React.Dispatch<React.SetStateAction<any[]>>;
  mainScene: BABYLON.Scene | any;
}

const ModelingTab = ({
  objects,
  setObjects,
  selectedObjects,
  setSelectedObjects,
  mainScene,
}: ModelingTabProps) => {
  const username = useAppSelector(selectUsername);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [cubeMenuVisible, setCubeMenuVisible] = useState(false);
  const [sphereMenuVisible, setSphereMenuVisible] = useState(false);
  const [cylinderMenuVisible, setCylinderMenuVisible] = useState(false);
  const [analyticMenuVisible, setAnalyticMenuVisible] = useState(false);
  const [lumpedPortMenuVisible, setLumpedPortMenuVisible] = useState(false);
  const [lumpedElementMenuVisible, setLumpedElementMenuVisible] =
    useState(false);
  const [distanceMenuVisible, setDistanceMenuVisible] = useState(false);
  const [translateMenuVisible, setTranslateMenuVisible] = useState(false);
  const [rotateMenuVisible, setRotateMenuVisible] = useState(false);
  const [scaleMenuVisible, setScaleMenuVisible] = useState(false);
  const [extrudeMenuVisible, setExtrudeMenuVisible] = useState(false);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isMergeLoading, setIsMergeLoading] = useState(false);
  const [isSubtractLoading, setIsSubtractLoading] = useState(false);
  const [isIntersectLoading, setIsIntersectLoading] = useState(false);
  const [isInsertLoading, setIsInsertLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  const models = useAppSelector(selectModels);
  const modelsToDraw = Object.values(models);
  const arrayModel = modelsToDraw.flat();
  const dispatch = useAppDispatch();

  const firstSelected: any = useAppSelector(selectFirstSelected);
  const materials = useAppSelector(selectMaterials);

  const { projectId } = useParams();

  const [selectedModelsLength, setSelectedModelsLength] = useState(0);
  const [selectedModelsObjectCategory, setSelectedModelsObjectCategory] =
    useState(false);

  useEffect(() => {
    hideMeshes("_meshLines", mainScene);
    hideMeshes("_meshLinesSecondary", mainScene);
    showMeshes("Distance ", mainScene);
    showMeshes("TextPlane", mainScene);
    showMeshes("_ground", mainScene);
  }, [mainScene]);

  useEffect(() => {
    let selectedModels: any[] = arrayModel.filter(
      (model: any) => model.selected && model.type !== "folder"
    );

    if (selectedModels.length > 0) {
      const objectCategory = selectedModels.every(
        (model: any) => model.category === "Objects"
      );
      setSelectedModelsObjectCategory(objectCategory);
    } else {
      setSelectedModelsObjectCategory(false);
    }

    setSelectedModelsLength(selectedModels.length);
  }, [arrayModel]);

  useEffect(() => {
    if (
      isMergeLoading ||
      isSubtractLoading ||
      isIntersectLoading ||
      isInsertLoading
    ) {
      toast.promise(new Promise((resolve, reject) => {}), {
        pending: "Performing boolean operation...",
        success: "Boolean operation completed.",
        error: "Boolean operation failed.",
      });
    } else {
      toast.dismiss();
    }
  }, [isMergeLoading, isSubtractLoading, isIntersectLoading, isInsertLoading]);

  const showMenu = (e: any, type: string) => {
    setCubeMenuVisible(type === "cube");
    setSphereMenuVisible(type === "sphere");
    setCylinderMenuVisible(type === "cylinder");
    setAnalyticMenuVisible(type === "analytic");
    setLumpedPortMenuVisible(type === "lumpedPort");
    setLumpedElementMenuVisible(type === "lumpedElement");
    setDistanceMenuVisible(type === "distance");
    setTranslateMenuVisible(false);
    setRotateMenuVisible(false);
    setScaleMenuVisible(false);

    const selectedModels = arrayModel.filter(
      (model) => model.selected && model.type !== "folder"
    );

    if (
      ["translate", "rotate", "scale", "extrude"].includes(type) &&
      selectedModels.length === 0
    ) {
      toast.error("Please select an object", {
        toastId: "error",
      });
    } else if (type === "translate") {
      setTranslateMenuVisible(true);
    } else if (type === "rotate") {
      setRotateMenuVisible(true);
    } else if (type === "scale") {
      setScaleMenuVisible(true);
    } else if (type === "extrude") {
      setExtrudeMenuVisible(true);
    }
    setMenuPosition({ x: e.pageX - 100, y: e.pageY + 30 });
  };

  const getMaterial = (color: string) => {
    if (materials) {
      const material = Object.keys(materials).find(
        (material) => material === color
      );
      if (material) {
        const meshMaterial = new BABYLON.StandardMaterial(material, scene);
        meshMaterial.diffuseColor = BABYLON.Color3.FromHexString(
          materials[material].color
        );
        return meshMaterial;
      }
      const meshMaterial = new BABYLON.StandardMaterial("PEC", scene);
      meshMaterial.diffuseColor = BABYLON.Color3.FromHexString(
        materials["PEC"].color
      );
      return meshMaterial;
    }
    return null;
  };

  const handleOpenUploader = (e: any) => {
    let fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  function loadMesh(
    fileName: string,
    url: string,
    extension: string,
    id: string,
    material = "PEC"
  ) {
    STLFileLoader.DO_NOT_ALTER_FILE_COORDINATES = true;

    let obj = {
      id: id,
      name: fileName,
      editable: false,
      status: "Added",
      type: "mesh",
      visible: true,
      material: material,
      category: "Objects",
      selected: false,
      parentId: 0,
      position: { x: 0, y: 0, z: 0 },
      scaling: { x: undefined, y: undefined, z: undefined },
      rotation: { x: 0, y: 0, z: 0 },
      origin: { x: 0, y: 0, z: 0 },
      url: url,
      extension: extension,
    };
    dispatch(modelAdded(obj));
  }

  const handleUploadSTL = (file: File) => {
    let fileName = file.name.split(".")[0];
    const fileExtension = file.name.split(".").pop();

    if (!fileExtension) {
      toast.error("Invalid file format. Please upload a STEP or STL file.", {
        toastId: "error",
      });
      return;
    }

    const ext = `.${fileExtension.toLowerCase()}`;

    if (ext !== ".stl" && ext !== ".step" && ext !== ".stp") {
      toast.error(
        ext +
          " file format is not supported. Please upload a STEP or STL file.",
        {
          toastId: "error",
        }
      );
    } else if (ext === ".step" || ext === ".stp") {
      const reader = new FileReader();
      let idArray: any = [];
      let fileNames: any = [];
      let urls: any = [];
      reader.onload = function (evt) {
        if (evt.target && typeof evt.target.result === "string") {
          setIsUploadLoading(true);
          step2stl(username, projectId, evt.target.result)
            .then((response) => {
              const files = response.files;
              Object.keys(files).forEach((file) => {
                let id = uuid();
                idArray.push(id);
                fileNames.push(file.toString());
                urls.push(`data:;base64,${files[file]}`);
                loadMesh(
                  file.toString(),
                  `data:;base64,${files[file]}`,
                  ".stl",
                  id
                );
              });
              const reader1 = new FileReader();
              reader1.onload = async function () {
                await dispatch(
                  addHistory({
                    payloadData: {
                      loadSTEPMesh: {
                        fileNames: fileNames,
                        urls: urls,
                        idArray: idArray,
                      },
                    },
                    currentUsername: username,
                    projectId: projectId || "",
                  })
                );
              };
              reader1.readAsDataURL(file);
              setIsUploadLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setIsUploadLoading(false);
            });
        }
      };
      reader.readAsText(file);
    } else {
      let id = uuid();
      const reader = new FileReader();
      reader.onload = async function (evt) {
        let filesrc = reader.result;
        await dispatch(
          addHistory({
            payloadData: {
              loadSTLMesh: {
                filesrc: filesrc,
                filename: fileName,
                ext: ext,
                id: id,
              },
            },
            currentUsername: username,
            projectId: projectId || "",
          })
        );
      };
      reader.readAsDataURL(file);
      const url = URL.createObjectURL(file);
      loadMesh(fileName, url, ext, id);
    }
  };

  const handleMerge = async (e: any) => {
    setIsMergeLoading(true);
    let selectedModels = arrayModel.filter(
      (model) => model.selected && model.type !== "folder"
    );
    if (selectedModels.length < 2) {
      toast.error("Please select at least two objects to merge.", {
        toastId: "error",
      });
      return;
    }

    if (scene) {
      const firstSelectedModel = selectedModels.find(
        (model) => model.id === firstSelected
      );

      if (!firstSelectedModel) {
        toast.error("No valid model selected for merging.", {
          toastId: "error",
        });
        return;
      }

      let data = { operation: "union", stls: [] as string[] };

      for (let model of selectedModels) {
        const mesh = scene.getMeshById(model.id) as BABYLON.Mesh;
        if (mesh) {
          let meshString = STLExport.CreateSTL([mesh], false, mesh.id);

          let lines = meshString.split("\n");

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.startsWith("\t\t\tvertex")) {
              const components = line.trim().split(/\s+/);
              const y = components[2];
              const z = components[3];
              components[2] = z;
              components[3] = y;
              lines[i] = components.join(" ");
            }
          }

          meshString = lines.join("\n");
          data.stls.push(window.btoa(meshString));
        }
      }

      const response = await booleanOperations(username, projectId, data);
      const file = window.btoa(response);

      loadMesh(
        firstSelectedModel.name,
        `data:;base64,${file}`,
        ".stl",
        uuid(),
        firstSelectedModel.material
      );

      selectedModels.forEach((model) => {
        const mesh = scene.getMeshById(model.id);
        if (mesh) {
          dispatch(modelAltered(mesh.id));
          dispatch(modelRemoved(mesh.id));
          mesh.dispose();
        }
      });

      const object1 = {
        ...firstSelectedModel,
        type: "mergedMesh",
        selected: false,
      };
      dispatch(modelAltered(object1));
      dispatch(modelRemoved(object1.id));
      dispatch(setFirstSelected(undefined));

      await dispatch(
        addHistory({
          payloadData: {
            merge: {
              firstSelectedModel: firstSelectedModel,
              selectedModels: selectedModels,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    }
    setIsMergeLoading(false);
  };

  const handleSubtract = async (e: any) => {
    setIsSubtractLoading(true);
    let selectedModels: any[] = arrayModel.filter(
      (model: any) => model.selected && model.type !== "folder"
    );
    if (selectedModels.length !== 2) {
      toast.error("Please select two objects to subtract.", {
        toastId: "error",
      });
      return;
    }

    if (scene) {
      const firstSelectedModel = selectedModels.find(
        (model) => model.id === firstSelected
      );
      const otherSelectedModel = selectedModels.find(
        (model) => model.id !== firstSelected
      );
      const mesh1 = scene.getMeshById(firstSelected) as BABYLON.Mesh;
      const mesh2 = scene.getMeshById(otherSelectedModel.id) as BABYLON.Mesh;

      if (mesh1 && mesh2) {
        let data: any = { operation: "subtract", stls: [] };

        let meshString;
        let lines;

        meshString = STLExport.CreateSTL(
          [mesh1 as unknown as BABYLON.Mesh],
          false,
          mesh1.id
        );

        lines = meshString.split("\n");
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          if (line.startsWith("\t\t\tvertex")) {
            const components = line.trim().split(/\s+/);
            const y = components[2];
            const z = components[3];
            components[2] = z;
            components[3] = y;
            lines[i] = components.join(" ");
          }
        }

        meshString = lines.join("\n");
        data.stls.push(window.btoa(meshString));

        meshString = STLExport.CreateSTL(
          [mesh2 as unknown as BABYLON.Mesh],
          false,
          mesh2.id
        );

        lines = meshString.split("\n");
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          if (line.startsWith("\t\t\tvertex")) {
            const components = line.trim().split(/\s+/);
            const y = components[2];
            const z = components[3];
            components[2] = z;
            components[3] = y;
            lines[i] = components.join(" ");
          }
        }

        meshString = lines.join("\n");
        data.stls.push(window.btoa(meshString));
        booleanOperations(username, projectId, data).then((response) => {
          const file = window.btoa(response);
          loadMesh(
            mesh1.name,
            `data:;base64,${file}`,
            ".stl",
            uuid(),
            firstSelectedModel.material
          );
          mesh1.dispose();
          mesh2.dispose();
          dispatch(modelRemoved(mesh1.id));
          dispatch(modelRemoved(mesh2.id));
          const object1 = {
            ...firstSelectedModel,
            type: "mergedMesh",
            selected: false,
          };
          dispatch(modelAltered(object1));
          dispatch(modelRemoved(object1.id));
          dispatch(setFirstSelected(undefined));
          setIsSubtractLoading(false);
        });

        await dispatch(
          addHistory({
            payloadData: {
              subtract: {
                firstSelectedModel: firstSelectedModel,
                selectedModels: selectedModels,
              },
            },
            currentUsername: username,
            projectId: projectId || "",
          })
        );
      }
    }
  };

  const handleIntersect = async (e: any) => {
    setIsIntersectLoading(true);
    let selectedModels = arrayModel.filter(
      (model) => model.selected && model.type !== "folder"
    );
    if (selectedModels.length < 2) {
      toast.error("Please select at least two objects to intersect.", {
        toastId: "error",
      });
      return;
    }

    if (scene) {
      const firstSelectedModel = selectedModels.find(
        (model) => model.id === firstSelected
      );

      if (!firstSelectedModel) {
        toast.error("No valid model selected for intersecting.", {
          toastId: "error",
        });
        return;
      }

      let data = { operation: "intersect", stls: [] as string[] };

      for (let model of selectedModels) {
        const mesh = scene.getMeshById(model.id) as BABYLON.Mesh;
        if (mesh) {
          let meshString = STLExport.CreateSTL([mesh], false, mesh.id);

          let lines = meshString.split("\n");

          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.startsWith("\t\t\tvertex")) {
              const components = line.trim().split(/\s+/);
              const y = components[2];
              const z = components[3];
              components[2] = z;
              components[3] = y;
              lines[i] = components.join(" ");
            }
          }

          meshString = lines.join("\n");
          data.stls.push(window.btoa(meshString));
        }
      }

      const response = await booleanOperations(username, projectId, data);
      const file = window.btoa(response);

      loadMesh(
        firstSelectedModel.name,
        `data:;base64,${file}`,
        ".stl",
        uuid(),
        firstSelectedModel.material
      );

      selectedModels.forEach((model) => {
        const mesh = scene.getMeshById(model.id);
        if (mesh) {
          dispatch(modelAltered(mesh.id));
          dispatch(modelRemoved(mesh.id));
          mesh.dispose();
        }
      });

      const object1 = {
        ...firstSelectedModel,
        type: "mergedMesh",
        selected: false,
      };
      dispatch(modelAltered(object1));
      dispatch(modelRemoved(object1.id));
      dispatch(setFirstSelected(undefined));

      await dispatch(
        addHistory({
          payloadData: {
            intersect: {
              firstSelectedModel: firstSelectedModel,
              selectedModels: selectedModels,
            },
          },
          currentUsername: username,
          projectId: projectId || "",
        })
      );
    }
    setIsIntersectLoading(false);
  };

  const handleInsert = async (e: any) => {
    setIsInsertLoading(true);
    let selectedModels: any[] = arrayModel.filter(
      (model: any) => model.selected && model.type !== "folder"
    );
    if (selectedModels.length !== 2) {
      toast.error("Please select two objects to insert.", {
        toastId: "error",
      });
      return;
    }

    if (scene) {
      const firstSelectedModel = selectedModels.find(
        (model) => model.id === firstSelected
      );
      const otherSelectedModel = selectedModels.find(
        (model) => model.id !== firstSelected
      );
      const mesh1 = scene.getMeshById(firstSelected) as BABYLON.Mesh;
      const mesh2 = scene.getMeshById(otherSelectedModel.id) as BABYLON.Mesh;

      if (mesh1 && mesh2) {
        let data: any = { operation: "subtract", stls: [] };

        let meshString;
        let lines;

        meshString = STLExport.CreateSTL(
          [mesh1 as unknown as BABYLON.Mesh],
          false,
          mesh1.id
        );

        lines = meshString.split("\n");
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          if (line.startsWith("\t\t\tvertex")) {
            const components = line.trim().split(/\s+/);
            const y = components[2];
            const z = components[3];
            components[2] = z;
            components[3] = y;
            lines[i] = components.join(" ");
          }
        }

        meshString = lines.join("\n");
        data.stls.push(window.btoa(meshString));

        meshString = STLExport.CreateSTL(
          [mesh2 as unknown as BABYLON.Mesh],
          false,
          mesh2.id
        );

        lines = meshString.split("\n");
        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          if (line.startsWith("\t\t\tvertex")) {
            const components = line.trim().split(/\s+/);
            const y = components[2];
            const z = components[3];
            components[2] = z;
            components[3] = y;
            lines[i] = components.join(" ");
          }
        }

        meshString = lines.join("\n");
        data.stls.push(window.btoa(meshString));
        booleanOperations(username, projectId, data).then((response) => {
          const file = window.btoa(response);
          loadMesh(
            mesh1.name,
            `data:;base64,${file}`,
            ".stl",
            uuid(),
            firstSelectedModel.material
          );
          mesh1.dispose();
          dispatch(modelRemoved(mesh1.id));
          const object1 = {
            ...firstSelectedModel,
            type: "mergedMesh",
            selected: false,
          };
          dispatch(modelAltered(object1));
          dispatch(modelRemoved(object1.id));
          dispatch(setFirstSelected(undefined));
          const object2 = {
            ...otherSelectedModel,
            selected: false,
          };
          dispatch(modelAltered(object2));
          setIsInsertLoading(false);
        });

        await dispatch(
          addHistory({
            payloadData: {
              insert: {
                firstSelectedModel: firstSelectedModel,
                selectedModels: selectedModels,
              },
            },
            currentUsername: username,
            projectId: projectId || "",
          })
        );
      }
    }
  };

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();

      if (e.dataTransfer?.types[0] === "Files") {
        setIsDragOver(true);
      }
    };

    const handleDragLeave = () => {
      setIsDragOver(false);
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      setIsDragOver(false);
      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files[0]) {
        handleUploadSTL(e.dataTransfer.files[0]);
      }
    };

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  return (
    <div className="relative">
      {isDragOver && (
        <div className="drag-overlay">
          <div className="dotted-square font-inter text-tsm">
            <div className="inner-content">
              <span>
                Drop&nbsp;
                <span className="font-semibold">STEP/STL</span>&nbsp;file to
                upload
              </span>
              <div className="h-4">
                <br />
              </div>
              <div className="icons-container">
                <MyIcon name="step-upload" />
                <MyIcon name="stl-upload" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="font-inter flex items-center justify-center mx-auto max-w-fit w-fit gap-3 p-3 min-[1540px]:p-0">
        {/* Upload STL */}
        <div className="text-center">
          <button
            onClick={handleOpenUploader}
            className="relative text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center rounded-md focus:outline-none w-max min-[1540px]:w-auto bg-[#EEF4FF] enabled:hover:bg-[#D3E2EF] enabled:active:bg-[#B1C7DE] disabled:opacity-75"
            disabled={isUploadLoading}
            style={{ whiteSpace: "nowrap" }}
          >
            <div
              className={`absolute inset-0 flex items-center justify-center ${
                isUploadLoading ? "block" : "hidden"
              }`}
            >
              <svg
                aria-hidden="true"
                className="w-6 h-6 animate-spin dark:text-gray-600 fill-gray-200 inline"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
            <div
              className={`absolute inset-0 flex items-center justify-center ${
                isUploadLoading ? "hidden" : "block"
              }`}
            >
              <MyIcon name="file-upload" />
              <input
                type="file"
                id="fileInput"
                accept=".stl,.step,.stp"
                multiple={false}
                onChange={(e) =>
                  e.target.files && handleUploadSTL(e.target.files[0])
                }
                className="absolute w-full h-full hidden z-10"
              />
              Upload STEP/STL
            </div>
            <div className="flex items-center justify-center opacity-0">
              <MyIcon name="file-upload" />
              Upload STEP/STL
            </div>
          </button>
          <p className="mt-1.5 text-sm font-normal text-[#475467]">
            Import CAD
          </p>
        </div>

        {/* Create */}
        <div className="text-center">
          <div className="flex w-max min-[1540px]:w-auto">
            {/* Cube Button */}
            <button
              onClick={(e) => {
                showMenu(e, "cube");
              }}
              className="text-[#344054] my-auto shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] bg-[#F3FEE7] hover:bg-[#E2EFD4] active:bg-[#BFCDB0] text-sm flex items-center justify-center rounded-bl-md rounded-tl-md focus:outline-none"
            >
              <MyIcon name="cube" />
              Cube
            </button>

            {/* Sphere Button */}
            <button
              onClick={(e) => {
                showMenu(e, "sphere");
              }}
              className="text-[#344054] my-auto shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] bg-[#F3FEE7] hover:bg-[#E2EFD4] active:bg-[#BFCDB0] text-sm flex items-center justify-center focus:outline-none border-l-0"
            >
              <MyIcon name="sphere" />
              Sphere
            </button>

            {/* Cylinder Button */}
            <button
              onClick={(e) => {
                showMenu(e, "cylinder");
              }}
              className="text-[#344054] my-auto shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] bg-[#F3FEE7] hover:bg-[#E2EFD4] active:bg-[#BFCDB0] text-sm flex items-center justify-center focus:outline-none border-l-0"
            >
              <MyIcon name="cylinder" />
              Cylinder
            </button>

            {/* Analytic Button */}
            <button
              onClick={(e) => {
                showMenu(e, "analytic");
              }}
              className="text-[#344054] my-auto shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] bg-[#F3FEE7] hover:bg-[#E2EFD4] active:bg-[#BFCDB0] text-sm flex items-center justify-center rounded-tr-md rounded-br-md focus:outline-none border-l-0"
            >
              <MyIcon name="analytic" />
              Analytic
            </button>
          </div>
          <p className="mt-1.5 text-sm font-normal text-[#475467]">
            Create shape
          </p>
        </div>

        {/* Transform */}
        <div className="text-center">
          <div className="flex w-max min-[1540px]:w-auto">
            <button
              onClick={(e) => {
                showMenu(e, "translate");
              }}
              disabled={
                selectedModelsLength === 0 || !selectedModelsObjectCategory
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center rounded-bl-md rounded-tl-md focus:outline-none bg-rose-50 enabled:hover:bg-[#EDD0D4] enabled:active:bg-[#E0AFB5] disabled:opacity-50`}
            >
              <MyIcon name="translate" />
              Translate
            </button>
            <button
              onClick={(e) => {
                showMenu(e, "rotate");
              }}
              disabled={
                selectedModelsLength === 0 || !selectedModelsObjectCategory
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center focus:outline-none border-l-0 bg-rose-50 enabled:hover:bg-[#EDD0D4] enabled:active:bg-[#E0AFB5] disabled:opacity-50`}
            >
              <MyIcon name="rotate" />
              Rotate
            </button>
            <button
              onClick={(e) => {
                showMenu(e, "scale");
              }}
              disabled={
                selectedModelsLength === 0 || !selectedModelsObjectCategory
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center focus:outline-none border-l-0 bg-rose-50 enabled:hover:bg-[#EDD0D4] enabled:active:bg-[#E0AFB5] disabled:opacity-50`}
            >
              <MyIcon name="scale" />
              Scale
            </button>
            <button
              onClick={(e) => {
                showMenu(e, "extrude");
              }}
              disabled={
                selectedModelsLength === 0 || !selectedModelsObjectCategory
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center focus:outline-none border-l-0 rounded-tr-md rounded-br-md bg-rose-50 enabled:hover:bg-[#EDD0D4] enabled:active:bg-[#E0AFB5] disabled:opacity-50`}
            >
              <MyIcon name="extrude" />
              Extrude
            </button>
          </div>
          <p
            className={`mt-1.5 text-sm font-normal text-[#475467]
            ${selectedModelsLength === 0 ? "opacity-50" : ""}
            `}
          >
            Tools
          </p>
        </div>

        {/* Operate */}
        <div className="text-center">
          <div className="flex w-max min-[1540px]:w-auto">
            <button
              onClick={handleMerge}
              disabled={
                selectedModelsLength < 2 ||
                !selectedModelsObjectCategory ||
                isMergeLoading
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center rounded-bl-md rounded-tl-md focus:outline-none bg-yellow-50 enabled:hover:bg-[#E3E2C3] enabled:active:bg-[#C8C9A8] disabled:opacity-50`}
            >
              {isMergeLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    className="w-16 h-5 animate-spin dark:text-gray-600 fill-gray-200 inline"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <MyIcon name="merge" />
                  Merge
                </>
              )}
            </button>
            <button
              onClick={handleSubtract}
              disabled={
                selectedModelsLength !== 2 ||
                !selectedModelsObjectCategory ||
                isSubtractLoading
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center focus:outline-none border-l-0  bg-yellow-50 enabled:hover:bg-[#E3E2C3] enabled:active:bg-[#C8C9A8] disabled:opacity-50`}
            >
              {isSubtractLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    className="w-16 h-5 animate-spin dark:text-gray-600 fill-gray-200 inline"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <MyIcon name="subtract" />
                  Subtract
                </>
              )}
            </button>
            <button
              onClick={handleIntersect}
              disabled={
                selectedModelsLength < 2 ||
                !selectedModelsObjectCategory ||
                isIntersectLoading
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center focus:outline-none border-l-0  bg-yellow-50 enabled:hover:bg-[#E3E2C3] enabled:active:bg-[#C8C9A8] disabled:opacity-50`}
            >
              {isIntersectLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    className="w-16 h-5 animate-spin dark:text-gray-600 fill-gray-200 inline"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <MyIcon name="intersect" />
                  Intersect
                </>
              )}
            </button>
            <button
              onClick={handleInsert}
              disabled={
                selectedModelsLength !== 2 ||
                !selectedModelsObjectCategory ||
                isInsertLoading
              }
              className={`text-[#344054] my-auto enabled:shadow-sm px-3 py-1.5 font-medium border border-[#D0D5DD] text-sm flex items-center justify-center rounded-tr-md rounded-br-md focus:outline-none border-l-0  bg-yellow-50 enabled:hover:bg-[#E3E2C3] enabled:active:bg-[#C8C9A8] disabled:opacity-50`}
            >
              {isInsertLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    className="w-16 h-5 animate-spin dark:text-gray-600 fill-gray-200 inline"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <MyIcon name="insert" />
                  Insert
                </>
              )}
            </button>
          </div>
          <p
            className={`mt-1.5 text-sm font-normal text-[#475467]
            ${selectedModelsLength <= 1 ? "opacity-50" : ""}
            `}
          >
            Boolean operations
          </p>
        </div>
      </div>
      <CubeMenu
        visible={cubeMenuVisible}
        setVisible={(value: boolean) => setCubeMenuVisible(value)}
      />
      <SphereMenu
        visible={sphereMenuVisible}
        setVisible={(value: boolean) => setSphereMenuVisible(value)}
      />
      <CylinderMenu
        visible={cylinderMenuVisible}
        setVisible={(value: boolean) => setCylinderMenuVisible(value)}
      />
      <AnalyticMenu
        visible={analyticMenuVisible}
        setVisible={(value: boolean) => setAnalyticMenuVisible(value)}
      />
      <TranslateMenu
        visible={translateMenuVisible}
        setVisible={(value: boolean) => setTranslateMenuVisible(value)}
        mainScene={scene}
      />
      <RotateMenu
        visible={rotateMenuVisible}
        setVisible={(value: boolean) => setRotateMenuVisible(value)}
        mainScene={scene}
      />
      <ScaleMenu
        visible={scaleMenuVisible}
        setVisible={(value: boolean) => setScaleMenuVisible(value)}
        mainScene={scene}
      />
      <ExtrudeMenu
        visible={extrudeMenuVisible}
        setVisible={(v) => setExtrudeMenuVisible(v)}
        mainScene={scene}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default ModelingTab;
